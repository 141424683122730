








































































import { Component, Watch, Vue } from 'vue-property-decorator';

import { WordManager } from '@/WordManager';

@Component
export default class Home extends Vue {
    private wordManager = new WordManager();

    private words: Word[] = [];

    /**
     * 並べ替えタイプ
     */
    private sortType: SortType = 'number';

    /**
     * 絞り込み: 初期ワード
     */
    private isInitWords = true;
    /**
     * 絞り込み: 2021/04/30 追加分
     */
    private isAdd20210430 = true;
    /**
     * 絞り込み: フツウヨミ
     */
    private isFutsuYomi = true;
    /**
     * 絞り込み: ウラヨミ
     */
    private isUraYomi = true;
    /**
     * 絞り込み: プリコネヨミ
     */
    private isPriconneYomi = true;

    /**
     * 検索で長音符を含めるか
     */
    private enableProlongedSoundMark = true;

    /**
     * 検索タイプ
     */
    private searchType: SearchType = 'fuzzy';
    private searchText = '';

    public mounted() {
        this.words = this.wordManager.Words;
    }

    /**
     * 検索する
     */
    @Watch('sortType')
    @Watch('isInitWords')
    @Watch('isAdd20210430')
    @Watch('isFutsuYomi')
    @Watch('isUraYomi')
    @Watch('isPriconneYomi')
    @Watch('searchType')
    @Watch('searchText')
    @Watch('enableProlongedSoundMark')
    private search() {
        this.words = this.wordManager
            .Search(this.searchText, this.searchType, this.enableProlongedSoundMark)
            .NarrowDown({
                initWords: this.isInitWords,
                add20210430: this.isAdd20210430,
                futsuYomi: this.isFutsuYomi,
                uraYomi: this.isUraYomi,
                priconneYomi: this.isPriconneYomi
            })
            .Sort(this.sortType)
            .Result();
    }

    /**
     * ヨミ毎のカード色を返す
     */
    private cardColor(yomi: Yomi) {
        if (yomi === 'フツウ') return this.hexToRgba('#73af55', 0.3);
        else if (yomi === 'ウラ') return this.hexToRgba('#2ea9ff', 0.3);
        else return this.hexToRgba('#ff7faa', 0.3);
    }

    /**
     * 16進数の色をRGBAに変換する
     */
    private hexToRgba(hexColor: string, opacity: number) {
        const [, r, g, b] = /#(.{2})(.{2})(.{2})/.exec(hexColor) || [];
        return `rgba(${parseInt(r, 16)}, ${parseInt(g, 16)}, ${parseInt(b, 16)}, ${opacity})`;
    }

    private clearSearchText_onClick() {
        this.searchText = '';
    }

    private card_onClick(word: Word) {
        // 前方一致検索にして選択したワードに続くワードを検索する
        this.searchType = 'prefix';
        this.searchText = word.end;
    }
}
