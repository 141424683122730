import { render, staticRenderFns } from "./App.vue?vue&type=template&id=364eb1fa&scoped=true&"
import script from "./App.vue?vue&type=script&lang=ts&"
export * from "./App.vue?vue&type=script&lang=ts&"
import style0 from "./App.vue?vue&type=style&index=0&lang=scss&"
import style1 from "./App.vue?vue&type=style&index=1&id=364eb1fa&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "364eb1fa",
  null
  
)

export default component.exports
import QLayout from 'quasar/src/components/layout/QLayout.js';
import QHeader from 'quasar/src/components/header/QHeader.js';
import QToolbar from 'quasar/src/components/toolbar/QToolbar.js';
import QToolbarTitle from 'quasar/src/components/toolbar/QToolbarTitle.js';
import QSpace from 'quasar/src/components/space/QSpace.js';
import QTabs from 'quasar/src/components/tabs/QTabs.js';
import QRouteTab from 'quasar/src/components/tabs/QRouteTab.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QDrawer from 'quasar/src/components/drawer/QDrawer.js';
import QPageContainer from 'quasar/src/components/page/QPageContainer.js';
import QPageSticky from 'quasar/src/components/page-sticky/QPageSticky.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QResizeObserver from 'quasar/src/components/resize-observer/QResizeObserver.js';
import QPage from 'quasar/src/components/page/QPage.js';
import QTab from 'quasar/src/components/tabs/QTab.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QLayout,QHeader,QToolbar,QToolbarTitle,QSpace,QTabs,QRouteTab,QSeparator,QBtn,QDrawer,QPageContainer,QPageSticky,QIcon,QResizeObserver,QPage,QTab});
