import { render, staticRenderFns } from "./Home.vue?vue&type=template&id=8c525888&scoped=true&"
import script from "./Home.vue?vue&type=script&lang=ts&"
export * from "./Home.vue?vue&type=script&lang=ts&"
import style0 from "./Home.vue?vue&type=style&index=0&id=8c525888&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8c525888",
  null
  
)

export default component.exports
import QPage from 'quasar/src/components/page/QPage.js';
import QRadio from 'quasar/src/components/radio/QRadio.js';
import QCheckbox from 'quasar/src/components/checkbox/QCheckbox.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QIntersection from 'quasar/src/components/intersection/QIntersection.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QImg from 'quasar/src/components/img/QImg.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';import Ripple from 'quasar/src/directives/Ripple.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QPage,QRadio,QCheckbox,QInput,QIntersection,QCard,QImg,QCardSection});qInstall(component, 'directives', {Ripple});
