




































import { Component, Vue } from 'vue-property-decorator';

import { WordManager } from '@/WordManager';

@Component
export default class Panels extends Vue {
    private wordManager = new WordManager();

    private get groups() {
        const map = new Map<string, Word[]>();
        for (const w of this.wordManager.Words) {
            const group = map.get(w.group);
            if (group) {
                group.push(w);
            }
            else {
                map.set(w.group, [w]);
            }
        }
        return map;
    }

    /**
     * ヨミ毎のバッジの色を返す
     */
    private badgeColor(yomi: Yomi) {
        if (yomi === 'フツウ') return '#73af55';
        else if (yomi === 'ウラ') return '#2ea9ff';
        else return '#ff7faa';
    }
}
