




















































































import { Component, Vue } from 'vue-property-decorator';
import scroll from 'quasar/src/utils/scroll.js';;

import { version, homepage } from '@/../package.json';

@Component({
    data: () => ({
        version,
        homepage
    })
})
export default class App extends Vue {
    private isDrawerOpen = false;

    private isScrolled = false;

    public mounted() {
        const body = scroll.getScrollTarget(document.documentElement);
        body.addEventListener('scroll', () => {
            if (scroll.getScrollPosition(body) > 100) {
                this.isScrolled = true;
            }
            else {
                this.isScrolled = false;
            }
        });

        if (scroll.getScrollPosition(body) > 100) {
            this.isScrolled = true;
        }
    }

    private btnPageTop_onClick() {
        scroll.animScrollTo(document.documentElement, 0, 300);
    }

    private onResize() {
        if (this.$q.screen.gt.xs) {
            this.isDrawerOpen = false;
        }
    }
}
